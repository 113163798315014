import React from 'react';
import Layout from '../../components/layouts';
import Banner from '../../components/common/Banner'
import Timeline from '../../components/about/story/Timeline'
import SEO from '../../components/common/SEO';

export default () => {
  // BANNER PROPS
  const title = () => (
    <div>
      <span>The </span>Workhub <span>Story </span>
      <span className="subheader">Our journey from the humble beginnings of SafetySync through triumphs and bloopers to the Workhub we are today.</span>
    </div>
  )
  const bannerProps = {
    title: title,
    subtitle: "About > Our Story",
    buttonLeft: {
      text: "Our Team",
      link: "/about/team/"
    },
    buttonRight: {
      text: "What's New",
      link: "/about/news/"
    }
  }
  const seo = {
    title: "Workhub | Our Story | Free EHS Management Software",
    description: "Learn Workhub's origin story & discover how we have dedicated ourselves to bringing you the best EHS Management software to boost compliance & safety in your workplace.",
    image: "/social-thumbs/thumb-team.jpg",
    // pathname,
    // article
  }

  return(
    <Layout>
       <SEO {...seo}/>
        <Banner {...bannerProps}/>
        <Timeline />
    </Layout>
  )
}
