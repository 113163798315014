import React from 'react'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'
const Timeline = () => {
    return(
        <section className="faq_area story_color">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12">
                    <VerticalTimeline layout={'1-column'}>

                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="February"
                            icon={"2006"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex'}}>
                            <p>
                            Company founder Brian Nodwell realizes as CIO of energy services company that workplace compliance requires a system and cannot find a software solution.
                            </p><img alt="Brian"src="https://www.safetysync.com/assets/img/timeline/feb2006.png" style={{float: 'right', maxHeight: '90px'}}/>
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="April"
                            icon={"'06"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Nodwell leaves his former employer, cashes in his stock options and incorporates Drilling Works Corp.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="July"
                            icon={"'06"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Company leases offices above nightclub in Calgary's Beltline district. Employees access office by way of a fire escape in the back.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="September"
                            icon={"'06"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <p>
                            Team briefly considers installed software, but decides to proceed with web-based application instead.(Whew!)
                            </p><img alt="Computer"src="https://www.safetysync.com/assets/img/timeline/sep2006.png" style={{float: 'left', maxHeight: '100px'}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="six"
                            className="vertical-timeline-element--fifteen month"
                            date="December"
                            icon={"'06"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Team brainstorms 15 names for the re-named company. The first 12 company names are already taken. Company settles for "SafetySync".
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="May"
                            icon={"2007"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Nodwell and his team work tirelessly building the company administration and worker safety portals, fueled by Slurpees and greasy donairs from a nearby eatery.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="July"
                            icon={"'07"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <p>
                            Nodwell purchases commercial espresso maker for office, which helps curb the number of early-morning and late-night trips to Starbucks. Development pace accelerates.
                            </p><img alt="Coffee cup"src="https://www.safetysync.com/assets/img/timeline/jul2007.png" style={{float: 'left', maxHeight: '100px'}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="September"
                            icon={"'07"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync signs up for first toll-free number. 1-800-4-SAFETY is taken… has to settle for 1-866-668-6402.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="seven"
                            className="vertical-timeline-element--nineteen month"
                            date="November"
                            icon={"'07"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >

                            <span style={{display: 'flex'}}>
                            <p>
                            Beta version of Online Training Module launched. First two courses available include TDG and WHMIS.
                            </p><img alt="WHMIS Symbols"src="https://www.safetysync.com/assets/img/timeline/nov2007.png" style={{float: 'right', maxHeight: '100px'}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="January"
                            icon={"2008"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Sales Team are discouraged to discover that competition will always under-cut SafetySync prices for online training. SafetySync decides to provide all training for free.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="August"
                            icon={"'08"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of MSDS module launched. First MSDS uploaded is Duron 15W-40 Heavy Duty Engine Oil.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="October"
                            icon={"'08"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <p>
                            Beta version of SafetySync Bulletin module launched. First bulletin is posted by Nodwell informing his staff that they cannot lock the door to the nightclub below (after the fire chief paid an impromptu visit).
                            </p><img alt="Keys" src="https://www.safetysync.com/assets/img/timeline/oct2008.png" style={{float: 'left', maxHeight: '100px'}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="eight"
                            className="vertical-timeline-element--fifteen month"
                            date="December"
                            icon={"'08"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Policy Acknowledgement module launched. First policy acknowledgement is SafetySync's own Smoking Policy, which is completely ignored by all patrons of the neighboring nightclub.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="January"
                            icon={"2009"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of 3rd Party Certificates Tracking module launched. First ticket uploaded is an expired Driver's License.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="June"
                            icon={"'09"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                            <p>
                            SafetySync Signs up its 100th customer.
                            </p><img alt="100th Customer "src="https://www.safetysync.com/assets/img/timeline/jun2009.png" style={{float: 'right', maxHeight: '100px'}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="July"
                            icon={"'09"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Safe Operating Procedure module launched. Procedure provides detailed steps required for "Grinding Wheel Operation".
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="September"
                            icon={"'09"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        First compliance email reminders (all 87 of them) are sent by the system. Average worker compliance is 37%.
                        </p><img alt="Finger with string tyed to it"src="https://www.safetysync.com/assets/img/timeline/sep2009.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="nine"
                            className="vertical-timeline-element--nineteen month"
                            date="November"
                            icon={"'09"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Forms module launched. First record submitted is a Hazard ID with deficiency logged as "Fan clutch went on Snubbing Unit."
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="February"
                            icon={"2010"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        Beta version of Perception Surveys module launched. First respondant Strongly Agrees that "My coworkers are competent to safely perform their work assignments."
                        </p><img alt="Blue Smiley Face"src="https://www.safetysync.com/assets/img/timeline/feb2010.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="May"
                            icon={"'10"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Future CTO Emiliya Ursova joins the company. Plants in the office recover from near death and bloom for the first time.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="July"
                            icon={"'10"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Audit Preparation module launched. Company receives cease and desist letter from eCompliance after comparing our tool to theirs.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="September"
                            icon={"'10"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        Tired of working above a nightclub, SafetySync staff move to a more modern (1917!) warehouse 10 blocks further East in Calgary's Warehouse District.
                        </p><img alt="Box with arrow pointing out"src="https://www.safetysync.com/assets/img/timeline/sep2010.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="ten"
                            className="vertical-timeline-element--fifteen month"
                            date="December"
                            icon={"'10"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Rewards module is launched. First worker earns 5 points for passing Working Alone quiz.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="February"
                            icon={"2011"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        SafetySync signs up its 1000th customer
                        </p><img alt="1000th Customer"src="https://www.safetysync.com/assets/img/timeline/feb2011.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="April"
                            icon={"'11"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Equipment Tracking module launched. First equipment entered was a 1985 Kenworth C500 Tri-Drive Winch Tractor.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="June"
                            icon={"'11"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        First safety reward claimed... a Sony Bluetooth Wireless Speaker for iPod and MP3 Players.
                        </p><img alt="Reward"src="https://www.safetysync.com/assets/img/timeline/jun2011.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="eleven"
                            className="vertical-timeline-element--nineteen month"
                            date="October"
                            icon={"'11"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Client worker acknowledges the 100,000th policy. It is an Earthquake Emergency Response Plan. 11 months later there is a 3.8 magnitude earthquake in Quebec, just 4,000 km away. There were no fatalities. Coincidence?
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="March"
                            icon={"2012"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        SafetySync signs up its 2000th customer. Staff begin to believe that there may actually be demand for compliance management software.
                        </p><img alt="2000th Customer"src="https://www.safetysync.com/assets/img/timeline/mar2012.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="May"
                            icon={"'12"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Beta version of Competency Assessment module is launched. First worker is deemed competent at "Loading and offloading skid steer".
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="July"
                            icon={"'12"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync provides 500,000th online training module. Worker barely passes Hearing Protection quiz with 84% score (score required to pass was set by client's administrator to 80%).
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="twelve"
                            className="vertical-timeline-element--fifteen month"
                            date="November"
                            icon={"'12"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        SafetySync signs up its 3000th customer. "Holy smokes," says Nodwell, "We're going to need more staff!" and begins actively hiring more programmers and designers.
                        </p><img alt="3000th Customer"src="https://www.safetysync.com/assets/img/timeline/nov2012.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>

                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="February"
                            icon={"2013"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Client worker uploads 50,000th safety certificate, and becomes only the 23rd worker in that company that is certified to operate a Forklift.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="May"
                            icon={"'13"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync staff have been too busy implementing clients to realize that their website has not been updated in 4 years. “Responsive design” gets added to team’s everyday vocabulary.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="June"
                            icon={"'13"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        The Alberta flood of 2013 swept the SafetySync team out of their building for a month due to downtown flooding. An overnight relocation to a temporary office, and high morale kept the business afloat during the wavy and unpredictable month.
                        </p><img alt="Flooded home"src="https://www.safetysync.com/assets/img/timeline/jun2013.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="September"
                            icon={"'13"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        Growth add sees more employees than available desks. Still bootstrapping, the company moves to an office near the airport where space doubles but rent remains the same.
                        </p><img alt="Rewards on dolly"src="https://www.safetysync.com/assets/img/timeline/sep2013.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="thirteen"
                            className="vertical-timeline-element--nineteen month"
                            date="December"
                            icon={"'13"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Company purchases dozens of 30" 2560x1600 px monitors for an ungodly amount of money. Now offers all employees 4 monitors each to create a panoramic desktop environment.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="January"
                            icon={"2014"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Company revenue doubles for the third straight year. Delusions of sustained Yahoo-type growth abound.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="February"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        5000th company signs up. Flush with unexpected revenue, SafetySync drops price to $5/user.
                        </p><img alt="5000th Customer"src="https://www.safetysync.com/assets/img/timeline/feb2014.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="March"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync finally negotiates a discount from UPS. Rewards begin shipping for “$9.99 or less” per package.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="April"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        Company begins stocking kitchen pantry with unlimited meals, snacks, and drinks. Staff sets new record for duration of marathon hackathon.
                        </p><img alt="Picture of mouse and cutlery"src="https://www.safetysync.com/assets/img/timeline/apr2014.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen  month"
                            date="May"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                                
                            Alarmed by sudden increase in caloric intake among staff, company initiates office Fitbit competition.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="July"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            In response to tightened anti-spam laws, SafetySync adds ability for workers to unsubscribe from compliance emails. Less than 1% choose to opt out.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="September"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        New trade show booth rolls out that has 10’ high truss system and six large televisions. Sales team barely manages to set it up in 2 hour time limit.
                        </p><img alt="Clock"src="https://www.safetysync.com/assets/img/timeline/sep2014.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="October"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync adds ability for customers to load their own online training course content. Feedback scores drop considerably.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="fourteen"
                            className="vertical-timeline-element--fifteen month"
                            date="December"
                            icon={"'14"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Incident Tracking beta tool rolls out. First incident description began with “Sheila was walking around the office in socks…” (Note: Sheila’s name has been changed to protect her identity.)
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="February"
                            icon={"2015"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        Despite making up less than half of workers in the system, women’s clothing orders exceed men’s clothing orders. Nodwell does some research and for the first time realizes the women’s clothing industry is twice the size of men’s (duh?), and quickly adjusts rewards selections to carry more women’s items.
                        </p><img alt="Clothing coming out of box"src="https://www.safetysync.com/assets/img/timeline/feb2015.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>

                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="March"
                            icon={"'15"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Oil price hits six-year low, prompting customers in that segment to deactivate 40% of their workers. Sales team begins drilling for customers in other industries.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="August"
                            icon={"'15"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync’s competitor, Intelex, announces $160 Million capital raise. By complete happenstance, Nodwell receives six inquiries in the next four weeks from venture capitalists.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="September"
                            icon={"'15"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            After complaints from frustrated safety consultants who manage multiple accounts, new “Switch Account” button gets added for applicable users.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="fifteen"
                            className="vertical-timeline-element--nineteen month"
                            date="December"
                            icon={"'15"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        Flush with all sorts of worker compliance data, SafetySync adds new Credential Filtering tool which allows administrators to find workers qualified for specific projects. Big Data initiative begins at SafetySync (but on a small scale).
                        </p><img alt="Hands Clapping"src="https://www.safetysync.com/assets/img/timeline/apr2008.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="February"
                            icon={"2016"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Upgraded certificate tracking to allow scheduling multiple workers to a single 3rd party training session. 5 employees are invited to “ATV Training” on May 8, 2016 at 8:30am.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="April"
                            icon={"'16"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        Support team receives calls from users worried that their portal had been compromised by Chinese cyberwarfare hackers. Apparently our recent demos included showing users how they could change their default language to Chinese.
                        </p><img alt="Phone"src="https://www.safetysync.com/assets/img/timeline/apr2016.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="August"
                            icon={"'16"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        After the inevitable death of Adobe Flash appears on the horizon, our first HTML5 course launches, setting off a wave of emails asking when everyone else's favourite course will be converted to the mobile-friendly format.
                        </p><img alt="HTML Icon"src="https://www.safetysync.com/assets/img/timeline/aug2016.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="October"
                            icon={"'16"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Revenue explodes by 25% in just 4 months, shocking all staff. Someone at the daily huddle whispers, “Hey, are we a high-growth company again?”. Another employee responds with, "Don’t jinx it!”
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="sixteen"
                            className="vertical-timeline-element--fifteen month"
                            date="December"
                            icon={"'16"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        SafetySync adds its 10,000th customer. Investors cancel their yacht orders when they realize most are on the Free plan.
                        </p><img alt="10,000th Customer"src="https://www.safetysync.com/assets/img/timeline/dec2016.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="January"
                            icon={"2017"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <p>
                        After 10 years of only allowing administrators to assign safety program elements by position, SafetySync also adds location-based requirements. Query load on servers quadruples.
                        </p><img alt="Globe"src="https://www.safetysync.com/assets/img/timeline/jan2017.png" style={{float: 'left', maxHeight: '100px'}} />
                        </span>                          
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="April"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Administrators are given the ability to restrict certain Reward categories. No more pet pens for your Pomeranian; just company caps for your cranium.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="May"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            At SafetySync AGM, shareholders are informed that the company made roughly <a target="_blank" rel="noopener noreferrer" href="https://money.cnn.com/2017/04/14/technology/uber-financials/index.html">$2.8 Billion more than Uber in 2016.</a>
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="June"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex'}}>
                        <p>
                        SafetySync adds functionality for administrators to “Approve” rewards redemptions after complaints that a few orders were shipped the day a worker was terminated.
                        </p><img alt="Reward with Checkmark"src="https://www.safetysync.com/assets/img/timeline/jun2017.png" style={{float: 'right', maxHeight: '100px'}} />
                        </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="July"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Added ability to link Incidents to Equipment. It’s important to know why a truck is written off apparently.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="August"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Joke of the week updated to HTML5. Users can once again enjoy some humour while working on their smartphones.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="September"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Company pays a ridiculous amount of money for workhub.com domain, then parks it.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="November"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex'}}>
                                <p>
                                Root Causes added to Incident Tracking. Why? Customers asked. Why? They wanted it. Why? It helps control for hazards. Why? Makes workplace safer. Why? Injuries should be prevented.
                                </p>
                                <img alt="Yellow Plant"src="https://www.safetysync.com/assets/img/timeline/nov2017.png"  style={{float: "right", maxHeight: '100px'}}></img>
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="seventeen"
                            className="vertical-timeline-element--nineteen month"
                            date="December"
                            icon={"'17"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync beats the Rule of 40 by over 30 percent in 2017. Management considers spending a bit more money in 2018.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="March"
                            icon={"2018"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Third bilingual course added. Merci!... De rien.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="May"
                            icon={"'18"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex'}}>
                            <img alt="Office with heart over it."src="https://www.safetysync.com/assets/img/timeline/may2018.png" style={{float: "left", maxHeight: '100px'}}></img>
                            <p>
                            Company moves into beautifully renovated 21,000 sq. ft. office recently vacated by another software company that needed to suddenly downsize. Thanks for the free tenant improvements!
                            </p>
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="June"
                            icon={"'18"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Rent expense inexplicably increases by 5 times. Free lunches are suspended for a few weeks in an ineffectual attempt to cut costs.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="August"
                            icon={"'18"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Much-delayed redesign of smartphone-optimized Worker Portal beta released. Goodbye menu. Hello dashboard.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="eighteen"
                            className="vertical-timeline-element--fifteen month"
                            date="October"
                            icon={"'18"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex'}}>
                                <p>
                                With the addition of “The Phoenix Project”, SafetySync’s library of business books reaches 200 titles.
                                </p><img alt="Stack of books with 200 on them"src="https://www.safetysync.com/assets/img/timeline/oct2018.png" style={{verticalAlign: 'middle', width: 112, height: 71}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen year"
                            date="March"
                            icon={"2019"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Increasing levels of signups lead to 20,000th customer. Support and implementation teams are able to manage triple the workload thanks to heavy reliance on Intercom chat and correspondingly fewer phone calls.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="May"
                            icon={"'19"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Company incorporates US subsidiary in Delaware and registers in Montana; the Treasure State. Hopes abound for figurative Oro y Plata in the US market.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="August"
                            icon={"'19"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Doug Penner joins Workhub as the company’s COO. Morale improves substantially as one-on-one meetings become more formal than simply passing in the hallway… “How’s it going?... Good.”
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--nineteen month"
                            date="September"
                            icon={"'19"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            SafetySync is the only Western Canadian company selected for Cohort 5 of the Lazaridis Scale-Up Program. Team pushes to get research in motion.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="nineteen"
                            className="vertical-timeline-element--nineteen month"
                            date="November"
                            icon={"'19"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Company changes its name to Workhub Software Corp. Adwords marketing team rejoices as they no longer have to capture misspelled searches of Synch and Sink.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="January"
                            icon={"2020"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            At the behest of mentors in the Lazaridis Scale Up Program, management starts formally tracking unit economics and discovers that 10 months CAC recovery is pretty decent.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="February"
                            icon={"2020"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', alignItems: 'center'}}>
                            <p>
                            In light of recent insight into absurdly-high customer LTV, company decides to launch in American dollar pricing at just USD$3.
                            </p>

                            <img alt="$3 Dollar bill with Trump on it" src="/app-icons/small/february-21-ourstory.png" style={{verticalAlign: 'middle', maxHeight: '80px'}} />
                          
                            </span>
                            
                        </VerticalTimelineElement>
                      
                        <VerticalTimelineElement
                            id="twenty"
                            className="vertical-timeline-element--fifteen month"
                            date="April"
                            icon={"'20"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Coronavirus shuts down the world economy. Workhub’s user count drops precipitously, but Workhub customer churn is only 0.9% through the first two months of the crisis as compliance and communication prove to be more critical than ever.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="June"
                            className="vertical-timeline-element--fifteen month"
                            date="June"
                            icon={"'20"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Given the risk that COVID-19 poses to worker health and safety, Worksite Screening gets bumped from the “3-5 Year” column on the Product Roadmap to “Need it Yesterday”.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="August"
                            className="vertical-timeline-element--fifteen month"
                            date="August"
                            icon={"'20"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            After a disastrous 2nd quarter and flat early-summer, growth returns in August with a record month for both (re)activated users and new customers. Not a V-shaped, L-shaped, or even W-shaped recovery. More like a “backwards-J-shaped recovery”. (Copyright pending)
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="September"
                            className="vertical-timeline-element--fifteen month"
                            date="September"
                            icon={"'20"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Workhub launches its new Suggestion tool. Where did we get the idea? Somebody suggested it.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="November"
                            className="vertical-timeline-element--fifteen month"
                            date="November"
                            icon={"'20"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            We polled our customers, and 90% of respondents thought we should offer Polls, specifically the kind for workers, not dancers or skiers. Done.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="February"
                            icon={"2021"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', alignItems: 'center'}}>
                            <p>
                            Safety Meetings tool rolls out. Sorry it took so long, but there were a lot of connections and workflows to build; it is not just a form!
                            </p>
                            </span>
                            
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="March"
                            icon={"'21"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        ><span style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
                             <p>
                                Whistleblower reporting tool added. Our customers are first class, so we hope they enable it, and then never need it. 
                                </p>
                                <img alt="Whistle"src="/app-icons/small/whistleblower-ourstory-sm.png" style={{float: 'left', maxHeight: '110px'}} />
                            </span>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="July"
                            icon={"2021"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', alignItems: 'center'}}>
                            <p>
                            Workhub purchases an industrial paper cutter to help satisfy growing demand for gift Thank You Cards and Scratch Cards.
                            </p>
                            </span>
                            
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen month"
                            date="September"
                            icon={"2021"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', alignItems: 'center'}}>
                            <p>
                            After a few unhappy instances of the Workhub going offline, customers ask, "Why can't you be more reliable... like Facebook?" 
                            </p>
                            </span>
                            
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--fifteen year"
                            date="February"
                            icon={"2022"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', alignItems: 'center'}}>
                            <p>
                            To help combat a closed border, significant supply chain problems, and raging inflation, Workhub expands its purchasing network to over 50 suppliers.
                            </p>
                            </span>
                            
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="May"
                            className="vertical-timeline-element--fifteen month"
                            date="May"
                            icon={"'22"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Workhub releases a major update to the Administrator dashboard. Are the notifications overwhelming? That depends on how good of a job you’re doing.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="August"
                            className="vertical-timeline-element--fifteen month"
                            date="August"
                            icon={"'22"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <p>
                            Workhub’s Rewards catalog selection exceeds 1000 items for the first time. Warehouse manager McMurry proclaims, “We’re going to need a bigger warehouse.”
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            id="November"
                            className="vertical-timeline-element--fifteen month"
                            date="November"
                            icon={"'22"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
                                <p>
                                Legendary entrepreneur and Workhub Chairman Jack Nodwell passes away. The board of directors loses 99% of its wisdom.
                                </p>
                                <img alt="Whistle"src="/app-icons/small/jack.png" style={{float: 'left', maxHeight: '110px'}} />
                            </span>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            id="January"
                            className="vertical-timeline-element--fifteen year"
                            date="January"
                            icon={"2023"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
                                <p>
                                Added ability for Administrators to customize Scratch Cards, assign sets to specific supervisors, monitor redemptions, and cancel lost cards.
                                </p>
                            </span>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            id="December"
                            className="vertical-timeline-element--fifteen month"
                            date="December"
                            icon={"2023"}
                            contentStyle={{display: 'flex', flexDirection: 'column-reverse'}}
                        >
                            <span style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
                                <p>
                                Tired of paying for modernizing landlords’ commercial spaces, then quickly outgrowing them, Workhub purchases the iconic Memorial Crossing building in Calgary.
                                </p>
                            </span>
                        </VerticalTimelineElement>
                       
                    </VerticalTimeline>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Timeline